import axios from "axios";

export default async function getLabelContent(printerLabel) {
  let printerLabelToUse = printerLabel ?? "accession-only";

  if (!printerLabelToUse) printerLabelToUse = "dymo";

  const labelName = `label-${printerLabelToUse}.zpl`;
  const overrideLabel = localStorage.getItem(labelName);

  if (overrideLabel) {
    return overrideLabel;
  }

  const { default: label } = await import(`./${labelName}`);

  const response = await axios.get(label);

  return response.data;
}
