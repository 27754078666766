import { useCallback } from "react";
import axios from "modules/common/utils/axios";
import dateOfBirthDateParser from "modules/common/utils/dateOfBirthDateParser";
import * as labelPrinting from "modules/services/label-printing";

export type Status = "success" | "danger" | "warning";

interface Response {
  icon: "success" | "error";
  message?: string | null;
  isSuccess: boolean;
  name: string | null;
  requisitionNumber: string | null;
  dateOfBirth: string | null;
  testTypeName: string | null;
}

interface Result {
  status?: Status;
  title?: string;
  message?: string;
  requisitionNumber?: string;
}

interface ValidateResult {
  valid: boolean;
  requisitionNumber: string;
}

function useBarcode() {
  const validateBarcode = useCallback(async (barcode: string) => {
    const { data } = await axios.post<ValidateResult>("/barcode/validate", {
      labOrderNumber: barcode,
    });

    return data;
  }, []);

  const validateAndAddBarcode = useCallback(
    async (barcode: string, shouldPrint: boolean = true) => {
      const result: Result = {
        title: `Barcode: ${barcode}`,
      };

      try {
        if (!barcode) {
          result.status = "warning";
          result.title = "Empty barcode";
          return result;
        }

        const data = await getLabelData(barcode);
        //const data = getLocalLabelData(barcode);

        result.status = data.icon === "success" ? "success" : "danger";
        result.message = data.message as string;
        result.requisitionNumber = data.requisitionNumber;

        if (data.isSuccess) {
          if (shouldPrint) {
            await print({
              requisitionNumber: data.requisitionNumber,
              name: data.name,
              dateOfBirth: data.dateOfBirth,
              testTypeName: data.testTypeName,
            });
          }
        }
      } catch (error) {
        result.status = "warning";
        result.message = "An unexpected error occurred";
      }

      return result;
    },
    []
  );

  const rejectBarcode = useCallback(async (barcode: string) => {
    const result: Result = {
      title: `Barcode: ${barcode}`,
    };

    try {
      const { data } = await axios.post<Response>("/Rejections", {
        labOrderNumber: barcode,
      });
    } catch (error) {
      result.status = "warning";
      result.message = "An unexpected error occurred";
    }

    return result;
  }, []);

  return { validateAndAddBarcode, validateBarcode, rejectBarcode };
}

async function print(model) {
  try {
    const printer = labelPrinting.factory();

    await printer.printModel({
      requisitionNumber: model.requisitionNumber,
      date: dateOfBirthDateParser({ date: model.dateOfBirth }),
      name: model.name,
      info: "",
      testTypeName: model.testTypeName,
    });
  } catch (error) {
    alert(error);
  }
}

async function getLabelData(barcode: string): Promise<Response> {
  const { data } = await axios.post<Response>("/barcode", {
    labOrderNumber: barcode,
  });

  return data;
}

function getLocalLabelData(barcode: string): Response {
  return {
    icon: "success",
    message: "",
    isSuccess: true,
    name: "",
    requisitionNumber: barcode.toUpperCase().trim(),
    dateOfBirth: "",
    testTypeName: "",
  };
}

export default useBarcode;
