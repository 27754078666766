import { useState, useRef, useCallback } from "react";
import useField from "modules/common/hooks/useField";
import ScanField from "modules/common/components/ScanField";
import axios from "modules/common/utils/axios";
import useToasts from "modules/app/hooks/useToasts";
import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiGlobalToastList,
  EuiHorizontalRule,
  EuiListGroup,
  EuiListGroupItem,
  EuiTitle,
} from "@elastic/eui";
import useTitle from "modules/common/hooks/useTitle";
import useBarcode from "modules/common/hooks/useBarcode";
import { BatchView } from "./BatchView";
import { PoolBatch } from "./PoolBatch";
import * as labelPrinting from "modules/services/label-printing";
import ConfirmationButton from "modules/common/components/ConfirmationButton";
import { PoolItem } from "./PoolItem";

const ZPL_POOL_LABEL_KEY = "basic-pool";
const ZPL_BLANK_LABEL_KEY = "blank";
const ZPL_BATCH_LABEL_KEY = "basic-batch";

interface CreateBatchResponse {
  id: string;
  code: string;
}

interface PoolResponse {
  barcode: string;
  isSuccess: boolean;
}

const printer = labelPrinting.factory();
const sampleStartPosition = 3;

async function printLabel({ model, labelName, copies }) {
  for (let i = 0; i < copies; i++) {
    await printer.printModel(model, { printerLabel: labelName });
  }
}

const BasicPooling = ({ title }) => {
  const ref = useRef<HTMLInputElement>();
  const barcodeField = useField({ value: "", title: "Scan Barcode" });
  const { toasts, addToast, removeToast } = useToasts();
  const { validateBarcode } = useBarcode();
  const [batch, setBatch] = useState<PoolBatch>(null);
  const [poolItems, setPoolItems] = useState<string[]>([]);
  const [poolBarcode, setPoolBarcode] = useState("");
  const [batchCompleted, setBatchCompleted] = useState(false);

  useTitle(title);

  const printBatchLabel = useCallback(async () => {
    await printLabel({
      model: { code: batch.code },
      labelName: ZPL_BATCH_LABEL_KEY,
      copies: 1,
    });
  }, [batch]);

  const printPoolLabel = useCallback(async (pool: PoolItem) => {
    await printLabel({
      model: {
        requisitionNumber: pool.barcode,
        items: pool.items,
      },
      labelName: ZPL_POOL_LABEL_KEY,
      copies: 1,
    });
  }, []);

  const startBatch = useCallback(async () => {
    const { data } = await axios.post<CreateBatchResponse>("/batch/create", {});

    setPoolItems([]);
    setBatch({ code: data.code, id: data.id, pools: [] });

    setPoolBarcode(
      `${data.code}S${sampleStartPosition.toString().padStart(2, "0")}`
    );

    setBatchCompleted(false);
    barcodeField.setState({
      value: "",
      loading: false,
      disabled: false,
    });

    await printLabel({
      model: { code: data.code },
      labelName: ZPL_BATCH_LABEL_KEY,
      copies: 4,
    });
  }, []);

  const onPressEnter = useCallback(
    async (barcode: string) => {
      if (!barcode) {
        return;
      }

      barcodeField.setState({ loading: true, disabled: true });

      const state = await validateBarcode(barcode);

      if (poolItems.includes(state.requisitionNumber)) {
        addToast({
          id: `${Date.now()}`,
          title: "Duplicate scan in pool",
          color: "danger",
          text: <>{state?.requisitionNumber}</>,
        });

        barcodeField.setState({ loading: false, disabled: false });
        setTimeout(() => {
          ref.current?.focus();
        });
        return;
      } else if (
        batch.pools
          .map((x) => x.items)
          .flat()
          .includes(state.requisitionNumber)
      ) {
        addToast({
          id: `${Date.now()}`,
          title: "Duplicate scan in batch",
          color: "danger",
          text: <>{state?.requisitionNumber}</>,
        });

        barcodeField.setState({ loading: false, disabled: false });
        setTimeout(() => {
          ref.current?.focus();
        });
        return;
      }

      addToast({
        id: `${Date.now()}`,
        title: state.valid ? "Order Found" : "Order Not Found",
        color: state.valid ? "success" : "danger",
        text: state.valid
          ? `Order found: ${state.requisitionNumber}`
          : `Order not found: ${state.requisitionNumber}`,
      });

      if (state.valid || state.requisitionNumber.startsWith("TEST")) {
        var newPoolItems = [...poolItems, state.requisitionNumber];

        if (poolItems.length < 2) {
          setPoolItems(newPoolItems);
        } else if (poolItems.length < 3) {
          const newPool = { barcode: poolBarcode, items: newPoolItems };

          const updatedBatch = {
            ...batch,
            pools: [...batch.pools, newPool],
          };
          try {
            const requestBody = {
              barcode: newPool.barcode,
              poolSize: 3,
              position: (
                updatedBatch.pools.length + sampleStartPosition
              ).toString(),
              batchCode: updatedBatch.code,
              batchId: updatedBatch.id,
              labOrderNumbers: newPool.items,
            };

            const { data } = await axios.post<PoolResponse>(
              "/pool",
              requestBody
            );

            if (data.isSuccess) {
              setBatch(updatedBatch);
              setPoolItems([]);
              setPoolBarcode(
                `${batch.code}S${requestBody.position.padStart(2, "0")}`
              );

              await printLabel({
                model: {
                  requisitionNumber: newPool.barcode,
                  items: newPool.items,
                },
                labelName: ZPL_POOL_LABEL_KEY,
                copies: 2,
              });
              await printLabel({
                model: {
                  text: "Placeholder: " + newPool.barcode,
                },
                labelName: ZPL_BLANK_LABEL_KEY,
                copies: 1,
              });
            }

            if (updatedBatch.pools.length >= 94) {
              setBatchCompleted(true);
            }
          } catch (error) {
            setPoolItems([]);
            addToast({
              id: `${Date.now()}`,
              title: error.response.data.title,
              color: "danger",
              text: (
                <>
                  {error.response.data.detail}
                  <br />
                  <b>Rescan the current pool samples</b>
                </>
              ),
            });
          }
        }
      }

      barcodeField.setState({ loading: false, disabled: false });

      setTimeout(() => {
        ref.current?.focus();
      });
    },
    [poolItems, batch, poolBarcode, barcodeField]
  );

  const listItems = poolItems.map((orderNumber) => (
    <EuiListGroupItem key={orderNumber} label={orderNumber} />
  ));

  return (
    <>
      <EuiGlobalToastList
        toasts={toasts}
        dismissToast={(toast) => removeToast(toast.id)}
        toastLifeTimeMs={5000}
      />
      <EuiFormRow fullWidth>
        <EuiFlexGroup gutterSize="s" responsive={true}>
          <EuiFlexItem grow={false}>
            <>
              <ConfirmationButton
                onConfirm={startBatch}
                title="New Batch"
                message="Are you sure you want to start a new batch?">
                New Batch
              </ConfirmationButton>
            </>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <>{batch && <>Batch Code: {batch?.code}</>}</>
          </EuiFlexItem>
          <EuiFlexItem grow={false} onClick={printBatchLabel}>
            <EuiButton>Reprint Batch Label</EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>
      {batch && (
        <>
          {batchCompleted ? (
            <EuiTitle key="batchCompleted">
              <h1>Batch Completed</h1>
            </EuiTitle>
          ) : (
            <ScanField
              {...barcodeField}
              inputRef={ref as any}
              onPressEnter={onPressEnter}
            />
          )}
          <EuiTitle key="currectPool">
            <h1>Current Pool - {poolBarcode}</h1>
          </EuiTitle>
          <EuiListGroup>{listItems}</EuiListGroup>

          <EuiHorizontalRule />

          <EuiTitle key="pools">
            <h2>Pools in Batch ({batch.pools.length})</h2>
          </EuiTitle>

          <BatchView
            batch={batch}
            onPrint={(pool) => {
              printPoolLabel(pool);
            }}
          />
        </>
      )}
    </>
  );
};

export default BasicPooling;
