import { useEffect } from "react";
import { EuiEmptyPrompt, EuiLoadingSpinner, EuiProvider } from "@elastic/eui";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { setTokenFactory } from "../services/authentication";
import { QZPrintProvider } from "../services/qz-label-printing";

import Scanner from "modules/scanner";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Settings } from "modules/settings";
import { RawPrint } from "modules/raw-print";
import AppLayout from "./AppLayout";
import Logo from "modules/common/components/Logo";
import BasicPooling from "modules/basic-pooling";
import Rejections from "modules/rejections";

const router = createBrowserRouter([
  {
    element: <AppLayout />,
    children: [
      {
        path: "/",
        element: <Scanner title="Accession" />,
      },
      {
        path: "/pool/basic",
        element: <BasicPooling title="Basic Pooling" />,
      },
      {
        path: "/print/raw",
        element: <RawPrint title="Raw Print" />,
      },
      {
        path: "/rejections",
        element: <Rejections title="Rejections" />,
      },
      {
        path: "/settings",
        element: <Settings title="Settings" />,
      },
    ],
  },
]);

const App = () => {
  const { getAccessTokenSilently } = useAuth0();
  setTokenFactory(getAccessTokenSilently);

  useEffect(() => {
    const qzProvider = QZPrintProvider.getInstance();
    const initPrinter = async () => {
      await qzProvider.initialize();
    };
    initPrinter();
    return () => qzProvider.disconnect();
  }, []);

  return (
    <EuiProvider colorMode="light">
      <RouterProvider router={router} />
    </EuiProvider>
  );
};

export default withAuthenticationRequired(App, {
  onRedirecting: () => (
    <EuiEmptyPrompt
      icon={<EuiLoadingSpinner size="xxl" />}
      title={
        <div>
          <Logo />
          <h2>Loading Application</h2>
        </div>
      }
    />
  ),
});
