import useTitle from "modules/common/hooks/useTitle";
import { PrinterSettings } from "./PrinterSettings";
import "./Settings.css";

function Settings({ title }) {
  useTitle(title);
  return (
    <>
      <PrinterSettings />
    </>
  );
}

export default Settings;
