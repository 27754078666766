import { useEffect, useState } from "react";
import { useSettings } from "./useSettings";

interface SelectOption {
  value: string;
  text: string;
}

export const options: SelectOption[] = [
  { value: "", text: "Select label" },
  { value: "dymo", text: "Dymo" },
  { value: "thermal", text: "Thermal" },
  { value: "accession-only", text: "Accession Number" },
];

const usePrinterLabel = () => {
  const { settings, setSetting } = useSettings();
  const [label, setLabel] = useState("dymo");

  useEffect(() => {
    if (!settings.printerLabel) return;

    setLabel(settings.printerLabel);
  }, []);

  const onChange = (label: string) => {
    setSetting("printerLabel", label);
    setLabel(label);
  };

  return { label, onChange };
};

export default usePrinterLabel;
