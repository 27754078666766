import { useState } from "react";

import {
  EuiButton,
  EuiButtonEmpty,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
} from "@elastic/eui";

import Show from "../Show";

function ConfirmationModal({
  title = "Confirmation",
  message = "",
  modalTitle = "Open",
  cancelTitle = "Cancel",
  continueTitle = "Continue",
  color = "primary",
  onConfirm,
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const closeModal = () => setIsModalVisible(false);
  const showModal = () => setIsModalVisible(true);

  const onConfirmClick = () => {
    closeModal();
    onConfirm();
  };

  return (
    <>
      <EuiButton fill onClick={showModal} color={color}>
        <span>{modalTitle}</span>
      </EuiButton>
      <Show when={isModalVisible}>
        <EuiOverlayMask>
          <EuiModal onClose={closeModal}>
            <EuiModalHeader>
              <EuiModalHeaderTitle>{title}</EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
              <span>{message}</span>
            </EuiModalBody>

            <EuiModalFooter>
              <EuiButtonEmpty onClick={closeModal}>
                <span>{cancelTitle}</span>
              </EuiButtonEmpty>
              <EuiButton fill onClick={onConfirmClick}>
                <span>{continueTitle}</span>
              </EuiButton>
            </EuiModalFooter>
          </EuiModal>
        </EuiOverlayMask>
      </Show>
    </>
  );
}

export default ConfirmationModal;
