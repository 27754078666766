import { useRef, useCallback } from "react";
import useField from "modules/common/hooks/useField";
import ScanField from "modules/common/components/ScanField";

import useBarcode from "../common/hooks/useBarcode";
import useToasts from "modules/app/hooks/useToasts";
import { EuiGlobalToastList } from "@elastic/eui";
import useTitle from "modules/common/hooks/useTitle";

const Rejections = ({ title }) => {
  const ref = useRef<HTMLInputElement>();
  const barcodeField = useField({ value: "", title: "Scan Barcode" });
  const { toasts, addToast, removeToast } = useToasts();
  const { rejectBarcode } = useBarcode();
  useTitle(title);

  const onPressEnter = useCallback((barcode: string) => {
    barcodeField.setState({ loading: true, disabled: true });

    setTimeout(async () => {
      const state = await rejectBarcode(barcode);

      addToast({
        id: `${Date.now()}`,
        title: state?.title,
        color: state?.status as any,
        text: <>{state?.message}</>,
      });

      barcodeField.setState({ loading: false, disabled: false });

      setTimeout(() => {
        ref.current?.focus();
      });
    }, 50);
  }, []);

  return (
    <>
      <EuiGlobalToastList
        toasts={toasts}
        dismissToast={(toast) => removeToast(toast.id)}
        toastLifeTimeMs={5000}
      />
      <ScanField
        {...barcodeField}
        inputRef={ref as any}
        onPressEnter={onPressEnter}
      />
    </>
  );
};

export default Rejections;
