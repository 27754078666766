import qz from "qz-tray";
import { cert, sign } from "./qz-print";
import { sift } from "qz-sift";

export class QZPrintProvider {
  private static instance: QZPrintProvider;

  private constructor() {}

  static getInstance() {
    if (!QZPrintProvider.instance) {
      QZPrintProvider.instance = new QZPrintProvider();
    }

    return QZPrintProvider.instance;
  }

  async initialize() {
    qz.security.setCertificatePromise((resolve: any) => {
      cert()
        .then(({ data: cert = "" }) => resolve(cert))
        .catch(console.error);
    });

    qz.security.setSignatureAlgorithm("SHA512");

    qz.security.setSignaturePromise((toSign: any) => {
      return async (resolve: any, reject: any) => {
        try {
          const { data } = await sign({
            request: toSign,
          });

          resolve(data.signedRequest);
        } catch (ex) {
          reject(ex);
        }
      };
    });

    qz.websocket.setClosedCallbacks(function () {
      // INACTIVE;
    });

    qz.websocket.setErrorCallbacks(function () {
      // ERROR;
    });

    await this.connect({});
  }

  async connect(config: any) {
    try {
      if (!qz.websocket.isActive()) {
        // WAITING;
        await qz.websocket.connect(config);
        // ACTIVE;
      }
    } catch (e) {
      console.log("Failed to connect", e);
      // ERROR;
    }
  }

  async print(
    printDocument: any,
    options: { printerName: string; config: any }
  ) {
    try {
      await this.connect({});
      const config = qz.configs.create(options.printerName, options.config);

      await qz.print(config, [
        {
          type: "raw",
          format: "command",
          flavor: "plain",
          data: printDocument,
          options: { language: "ZPL" },
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  }

  async getListOfPrinters() {
    try {
      await this.connect({});
      const printers = await qz.printers.find();
      return (printers || []) as string[];
    } catch (error) {
      console.log(error);
    }
  }

  async rawPrinters() {
    const data = await qz.printers.details();

    const filteredData = sift.keep(data, { type: "raw" });
    console.log(filteredData);
  }

  async launch() {
    if (!qz.websocket.isActive()) {
      window.location.assign("qz:launch");
      const result = await this.connect({ retries: 10, delay: 1 });
      return result;
    }
  }

  disconnect() {
    try {
      if (qz.websocket.isActive()) {
        qz.websocket.disconnect();
      }
    } catch (e) {
      console.log("Failed to disconnect", e);
    }
  }
}
