import { getCachedSettings } from "modules/settings/hooks/useSettings";
//import { dymoLabelPrinter } from "../dymo-label-printing";
import { qzLabelPrinter } from "../qz-label-printing";

export type PrintSettings = {
  printerLabel?: string;
  printerName?: string;
  printerType?: "qz" | "dymo";
} | null;

export interface LabelPrinter {
  printModel(model: any, settings?: PrintSettings): Promise<void>;
  previewModel(model: any, settings?: PrintSettings): Promise<void>;
}

export function factory(): LabelPrinter {
  //const settings = getCachedSettings();
  const provider = qzLabelPrinter;
  //settings["printerType"] === "qz" ? qzLabelPrinter : dymoLabelPrinter;
  return provider;
}
