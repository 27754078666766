import { useState } from "react";
import { EuiButton, EuiFormRow, EuiPanel, EuiTextArea } from "@elastic/eui";
import * as labelPrinting from "modules/services/label-printing";
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import useTitle from "modules/common/hooks/useTitle";
const ZPL_LABEL_KEY = "custom-ui";
const ZPL_LABEL_FILE_NAME = "label-custom-ui.zpl";
const LABEL_DATA_KEY = "custom-ui-data.json";
const SAMPLE_LABEL = `^XA
^MMT
^FO40,30,0^BY2,2.5,120^BAN,,Y,N^FH^FD<%= model.requisitionNumber %>^FS
^PQ1,0,1,Y
^XZ`;

function RawPrint({ title }) {
  useTitle(title);
  const [data, setData] = useState(
    JSON.parse(localStorage.getItem(LABEL_DATA_KEY) ?? "{}")
  );
  const [zpl, setZpl] = useState(
    localStorage.getItem(ZPL_LABEL_FILE_NAME) ?? SAMPLE_LABEL
  );

  const print = async () => {
    const printer = labelPrinting.factory();
    if (Array.isArray(data)) {
      for (let item in data) {
        await printer.printModel(
          {
            model: data[item],
          },
          { printerLabel: ZPL_LABEL_KEY }
        );
      }
    } else {
      await printer.printModel(
        {
          model: data,
        },
        { printerLabel: ZPL_LABEL_KEY }
      );
    }
  };

  const onZplChange = (e) => {
    setZpl(e.target.value);
    localStorage.setItem(ZPL_LABEL_FILE_NAME, e.target.value);
  };

  const onDataChange = (json) => {
    setData(json);
    localStorage.setItem(LABEL_DATA_KEY, JSON.stringify(json));
  };

  return (
    <EuiPanel>
      <EuiFormRow label="Input">
        <Editor value={data} onChange={(json) => onDataChange(json)} />
      </EuiFormRow>
      <EuiFormRow label="ZPL">
        <EuiTextArea value={zpl} onChange={onZplChange} />
      </EuiFormRow>
      <EuiFormRow>
        <EuiButton onClick={print}>Print</EuiButton>
      </EuiFormRow>
    </EuiPanel>
  );
}

export default RawPrint;
