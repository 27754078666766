import {
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiListGroup,
  EuiListGroupItem,
} from "@elastic/eui";
import { PoolBatch } from "./PoolBatch";
import { PoolItem } from "./PoolItem";
import { Comparators } from "@elastic/eui";

export const BatchView = ({
  batch,
  onPrint,
}: {
  batch: PoolBatch;
  onPrint: (PoolItem) => void;
}) => {
  const columns: Array<EuiBasicTableColumn<PoolItem>> = [
    {
      field: "barcode",
      name: "Barcode",
    },
    {
      field: "items",
      name: "Scans",
      render: (items: PoolItem[]) => {
        const listItems = items.map((orderNumber) => (
          <EuiListGroupItem label={orderNumber} />
        ));
        return <EuiListGroup>{listItems}</EuiListGroup>;
      },
    },
    {
      name: "Actions",
      actions: [
        {
          name: "Print",
          description: "Reprint",
          icon: "tag",
          type: "icon",
          onClick: (item: PoolItem) => {
            onPrint(item);
          },
        },
      ],
    },
  ];

  if (!batch) return null;

  const items = batch.pools
    .slice(0)
    .sort(Comparators.property("barcode", Comparators.default("desc")));

  return (
    <>
      <EuiBasicTable
        tableCaption="Pool Batch Items"
        items={items}
        columns={columns}
      />
    </>
  );
};
