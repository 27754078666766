import ConfirmationModal from "../ConfirmationModal";

function ConfirmationButton({
  children,
  onConfirm,
  title = "Confirm",
  message = "Are you sure?",
}) {
  return (
    <ConfirmationModal
      modalTitle={children}
      title={title}
      message={message}
      onConfirm={onConfirm}
      continueTitle="Yes"></ConfirmationModal>
  );
}

export default ConfirmationButton;
