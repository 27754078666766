import React from "react";
import ReactDOM from "react-dom";
import App from "modules/app";
import { EuiProvider } from "@elastic/eui";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";
import config from "./modules/common/utils/auth_config";

import "@elastic/eui/dist/eui_theme_light.css";

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={config.domain}
      clientId={config.clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: config.audience,
        scope: "openid profile email can:accession",
      }}>
      <EuiProvider>
        <App />
      </EuiProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
