import { LabelPrinter, PrintSettings } from "modules/services/label-printing";
import { getCachedSettings } from "modules/settings/hooks/useSettings";
import prepareLabel from "./labelGenerator";
import { printLabel } from "./labelPrinter";

export * from "./QZPrintProvider";

export const qzLabelPrinter: LabelPrinter = {
  printModel: async (model: any, settings?: PrintSettings) => {
    const finalSettings = { ...getCachedSettings(), ...settings };

    const label = await prepareLabel(model, finalSettings.printerLabel);
    await printLabel(label, finalSettings);
  },
  previewModel: async (model: any, settings?: PrintSettings) => {
    throw new Error("Not implemented");
  },
};
