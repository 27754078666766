import { ReactElement } from "react";
import {
  EuiPageTemplate,
  EuiPageTemplateProps,
  EuiPageHeaderProps,
  EuiPageSidebarProps,
} from "@elastic/eui";

const Layout = ({
  button = <></>,
  sidebar,
  header,
  panelled,
  sidebarSticky,
  offset,
  grow,
  children,
}: {
  children?: ReactElement;
  button?: ReactElement;
  sidebar?: ReactElement;
  header?: EuiPageHeaderProps;
  panelled?: EuiPageTemplateProps["panelled"];
  // For fullscreen only
  sidebarSticky?: EuiPageSidebarProps["sticky"];
  offset?: EuiPageTemplateProps["offset"];
  grow?: EuiPageTemplateProps["grow"];
}) => {
  return (
    <EuiPageTemplate panelled={panelled} offset={offset} grow={grow}>
      {sidebar && (
        <EuiPageTemplate.Sidebar sticky={sidebarSticky}>
          {sidebar}
        </EuiPageTemplate.Sidebar>
      )}
      {header && (
        <EuiPageTemplate.Header {...header} rightSideItems={[button]} />
      )}
      {children}
    </EuiPageTemplate>
  );
};

export default Layout;
