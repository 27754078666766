import _ from "underscore";
import getLabelContent from "./labelFetcher";

export default async function prepareLabel(model, printerLabel) {
  const template = await getCachedLabelTemplate(printerLabel);
  return template(model);
}

async function getCachedLabelTemplate(printerLabel) {
  const labelContent = await getLabelContent(printerLabel);
  const labelTemplate = _.template(labelContent);

  return labelTemplate;
}
