import { useState, useRef, useCallback } from "react";
import useField from "modules/common/hooks/useField";
import ScanField from "modules/common/components/ScanField";

import useBarcode from "../common/hooks/useBarcode";
import useToasts from "modules/app/hooks/useToasts";
import { EuiFormRow, EuiGlobalToastList, EuiSwitch } from "@elastic/eui";
import useTitle from "modules/common/hooks/useTitle";

const Scanner = ({ title }) => {
  const ref = useRef<HTMLInputElement>();
  const barcodeField = useField({ value: "", title: "Scan Barcode" });
  const { toasts, addToast, removeToast } = useToasts();
  const { validateAndAddBarcode } = useBarcode();
  const [print, setPrint] = useState(true);
  useTitle(title);

  const onPressEnter = useCallback(
    (barcode: string) => {
      barcodeField.setState({ loading: true, disabled: true });

      setTimeout(async () => {
        const state = await validateAndAddBarcode(barcode, print);

        addToast({
          id: `${Date.now()}`,
          title: state?.title,
          color: state?.status as any,
          text: <>{state?.message}</>,
        });

        barcodeField.setState({ loading: false, disabled: false });

        setTimeout(() => {
          ref.current?.focus();
        });
      }, 50);
    },
    [print]
  );

  return (
    <>
      <EuiGlobalToastList
        toasts={toasts}
        dismissToast={(toast) => removeToast(toast.id)}
        toastLifeTimeMs={5000}
      />
      <EuiFormRow fullWidth>
        <EuiSwitch
          label="Print Label"
          checked={print}
          onChange={(e) => setPrint(e.target.checked)}
        />
      </EuiFormRow>
      <ScanField
        {...barcodeField}
        inputRef={ref as any}
        onPressEnter={onPressEnter}
      />
    </>
  );
};

export default Scanner;
