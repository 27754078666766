import { useState } from "react";
import { Outlet } from "react-router-dom";
import Layout from "./Layout";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiPanel,
  EuiSideNav,
  htmlIdGenerator,
} from "@elastic/eui";

import Logo from "modules/common/components/Logo";
import styles from "./index.module.css";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const AppLayout = () => {
  const [selectedItemName, setSelectedItem] = useState("");
  const navigate = useNavigate();
  const { logout, user } = useAuth0();
  const [title, setTitle] = useState("");

  const selectItem = (name: string, to: string) => {
    setSelectedItem(name);
    navigate(to);
  };

  const createItem = (name, data: any) => {
    const key = data.key ?? name;
    return {
      id: key,
      name,
      isSelected: selectedItemName === key,
      onClick: () => selectItem(key, data.to),
      ...data,
    };
  };

  const sideNav = [
    createItem("Accession", {
      to: "/",
      icon: <EuiIcon type="beaker" size="m" />,
    }),

    createItem("Pooling", {
      onClick: undefined,
      key: "pooling",
      icon: <EuiIcon type="users" size="m" />,
      items: [
        createItem("Basic Pool", {
          to: "/pool/basic",
          icon: <EuiIcon type="logstashInput" size="m" />,
        }),
      ],
    }),
    
    createItem("Advance", {
      onClick: undefined,
      key: "advanced",
      icon: <EuiIcon type="advancedSettingsApp" size="m" />,
      items: [
        createItem("Raw Printing", {
          to: "/print/raw",
          icon: <EuiIcon type="nested" size="m" />,
        }),
        createItem("Rejections", {
          to: "/rejections",
          icon: <EuiIcon type="beaker" size="m" />,
        }),
      ],
    }),
    createItem("Settings", {
      key: "settings",
      to: "/settings",
      icon: <EuiIcon type="wrench" size="m" />,
    }),
    {
      name: "Logout",
      id: htmlIdGenerator("logout")(),
      onClick: () =>
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        }),
    },
  ];

  return (
    <Layout
      panelled={true}
      sidebarSticky={false}
      grow={true}
      sidebar={
        <EuiSideNav
          heading={
            <EuiFlexGroup justifyContent="spaceAround" direction="column">
              <EuiFlexItem grow={true}>
                <Logo className={styles.logo} />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>{user.name}</EuiFlexItem>
            </EuiFlexGroup>
          }
          items={sideNav}></EuiSideNav>
      }
      header={{ pageTitle: title }}>
      <EuiPanel>
        <Outlet context={{ title, setTitle }} />
      </EuiPanel>
    </Layout>
  );
};

export default AppLayout;
